<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>VPS Heavy</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[3]"/>
        <h2>VPS Heavy</h2>
        <p>De beste keuze voor een VPS, op basis van de hoogste limieten. U heeft maandelijks 250GB dataverkeer tot uw beschikking en u kunt doorlopend gebruik maken van 100GB HDD opslagruimte op onze servers. We zorgen voor de virtuele afbakening daarvan, waardoor u de vrijheid heeft van een eigen server. Het is niet nodig om te investeren in een eigen fysieke server, in plaats daarvan maakt u vanaf €150 per maand gebruik van een A-merk apparatuur virtual private server.</p>
        <h3>Host meerdere websites</h3>
        <p>Met het grootste pakket voor VPS kunt u eenvoudig meerdere websites en/of applicaties hosten. We zorgen er met de schijfruimte voor dat u daar meer dan voldoende aan hebt, terwijl het dataverkeer haast onbeperkt is. Dankzij 8GB RAM en 4 CPU cores is er meer dan voldoende rekenkracht en opslagruimte beschikbaar om alle processen op de virtuele eigen server zo soepel mogelijk te laten verlopen.</p>
        <ul>
          <li>Vrijheid van een virtuele server</li>
          <li>250GB dataverkeer voor al uw websites of applicaties</li>
          <li>100GB HDD opslagruimte</li>
          <li>Kies zelf het besturingssysteem</li>
        </ul>
        <h3>Kies zelf het besturingssysteem (OS)</h3>
        <p>Op uw eigen virtuele server kunt u het besturingssysteem kiezen dat u wilt gebruiken. De VPS biedt een maximale vrijheid, zoals u die ook zou genieten met een eigen fysieke server. Het is aan de andere kant niet nodig om daarin te investeren, in plaats daarvan betaalt u een vast bedrag per maand. We bieden u met het grootste pakket voor een professionele Virtual Private Server daarmee wel de voordelen van een eigen server, zonder dat daar aan de andere kant ook de benodigde investering bij komt kijken.</p>
        <p><i>“Maximale vrijheid, haast zonder limiet: dat is hoe we dit pakket voor onze VPS ervaren. Fijn dat de limieten zo hoog zijn, waardoor we ons daar geen zorgen over hoeven te maken”</i>, aldus een tevreden organisatie.</p>
        <h3>VPS hosting met 4 CPU cores</h3>
        <p>Ons pakket voor professionele VPS hosting zorgt er met 4 CPU cores voor dat er altijd voldoende rekenkracht beschikbaar is om alle processen zo soepel mogelijk te laten verlopen. U kunt rekenen op de maximale rekenkracht en hoogste limieten voor het RAM-geheugen, de HDD-schijfruimte en het dataverkeer dat er maandelijks is toegestaan. Direct online te bestellen.</p>
      </div>
      <CloudServersRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import CloudServersRightSideBlocks from '@/components/CloudServersRightSideBlocks.vue';

export default {
  name: 'VPSHeavy',
  components: {
    Breadcrumbs,
    Price,
    CloudServersRightSideBlocks,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>